/*
 * @Description: 用户登陆接口
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:46
 * @LastEditors: adlj
 * @LastEditTime: 2022-12-14 13:05:15
 */
import request from '@/libs/axios'
import setting from '@/setting'
import QS from 'qs'

const SCOPE = 'server'
const TENANT_ID = setting.server.tenantId
const AUTHORIZATION = setting.server.authorization

export function _ReqGet (data) {
  return request({
    url: '/code',
    method: 'get',
    data
  })
}

export function _ReqCheck (data) {
  return request({
    url: '/code/check',
    method: 'post',
    params: data
  })
}

export function _LoginByAccount (data) {
  const grantType = 'password'
  const dataObj = QS.stringify({ username: data.username, password: data.password })
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      Authorization: AUTHORIZATION
    },
    method: 'post',
    params: { randomStr: data.randomStr, code: data.code, grant_type: grantType },
    data: dataObj

  })
}

export function refreshToken (refreshToken) {
  const grantType = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'post',
    params: { refresh_token: refreshToken, grant_type: grantType, scope: SCOPE }
  })
}

export function _Logout () {
  return request({
    url: '/auth/token/logout',
    method: 'delete'
  })
}

export function _GetUserInfo () {
  return request({
    url: '/admin/member/info',
    method: 'get'
  })
}

export function _RefreshToken (refreshToken) {
  const grantType = 'refresh_token'
  return request({
    url: '/auth/oauth/token',
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'post',
    params: { refresh_token: refreshToken, grant_type: grantType, scope: SCOPE }
  })
}

/**
 * _GetRegisterCode
 * @description 注册用户获取短信验证码
 */
export function _GetRegisterCode (phone) {
  return request({
    url: '/sms/send/member/register/' + phone,
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'GET'
  })
}

/**
 * _RegisterUser
 * @description 用户注册
 */
export function _RegisterUser (phone, code, password) {
  return request({
    url: '/tf-trading/member/register',
    // url: '/platform/sys-member/app/register',
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'POST',
    data: { ...phone, ...code, ...password }
  })
}

/**
 * _GetUpdatePasswordCode
 * @description 发送重置密码短信验证码
 */
export function _GetUpdatePasswordCode (phone) {
  return request({
    url: '/sms/send/member/update/password/' + phone,
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'GET'
  })
}

/**
 * _PutForgetPwd
 * @description 重置密码
 */
export function _PutForgetPwd (code, password, repeatPassword, phone) {
  return request({
    url: '/platform/sys-member/app/password/reset?code=' +
      code + '&password=' + password + '&repeatPassword=' + repeatPassword + '&phone=' + phone,
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'PUT'
  })
}

/**
 * _GetUpdatePhoneCode
 * @description 发送修改手机号的验证码
 */
export function _GetUpdatePhoneCode (phone) {
  return request({
    url: '/sms/send/member/update/phone/' + phone,
    headers: {
      isToken: false,
      'TENANT-ID': TENANT_ID,
      Authorization: AUTHORIZATION
    },
    method: 'GET'
  })
}

/**
 * _GetMemberByPhone
 * @description 根据手机号查询会员信息
 */
export function _GetMemberByPhone (phone) {
  return request({
    url: `/admin/member/phone/${phone}`,
    method: 'GET'
  })
}

/**
 * _GetOldPhoneValidCode
 * @description 发送验证原手机号的验证码
 */
export function _GetOldPhoneValidCode (phone) {
  return request({
    url: `/sms/send/phone/valid/old/${phone}`,
    method: 'GET'
  })
}

/**
 * _GetOldPhoneValid
 * @description 验证原手机号的验证码
 */
export function _GetOldPhoneValid (phone, code) {
  return request({
    url: '/sms/send/valid/phone/old',
    method: 'GET',
    params: {
      phone,
      code
    }
  })
}

/**
 * _getMemberPhoneCode
 * @description 验证用户输入的验证码
 */
export function _PutUpdatePhone (id, code, updatePhone) {
  return request({
    url: '/admin/member/update/phone?memberId=' + id + '&code=' + code + '&updatePhone=' + updatePhone,
    method: 'PUT'
  })
}

/**
 * _GetUserId
 * @description 获取用户Id
 */
export function _GetUserId () {
  return request({
    url: '/tf-trading/member/userId',
    method: 'POST'
  })
}

/**
 * _GetResetCodeValid
 * @description 验证重置密码的验证码
 */
export function _GetResetCodeValid (phone, code) {
  return request({
    url: '/sms/send/reset_code/valid',
    method: 'GET',
    params: {
      phone,
      code
    }
  })
}
