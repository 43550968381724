<!--
 * @Description: 用户基本信息组件
 * @Author: Pancras
 * @Date: 2022-07-04 16:09:02
 * @LastEditors: szw
 * @LastEditTime: 2022-11-07 14:39:55
-->
<template>
  <div class="u-i-container"
       flex="dir:top">
       <div class="r-icon">
        <router-link to="/uc/message">
          <el-badge :value="unreadNum"
                    class="item"
                    type="success">
            <img src="~@/assets/uc/email.png"
                 alt="">
          </el-badge>
        </router-link>
      </div>
    <!-- <div class="sign1" v-if="userApproveInfo.isAuthentication === '0' || userApproveInfo.isAuthentication === '1'" @click="sellerApprove">卖家未认证</div>
    <div class="sign2" v-else-if="userApproveInfo.isAuthentication === '2' || userApproveInfo.isAuthentication === '3'">卖家已认证</div>
    <div class="sign3" v-if="userApproveInfo.isAuthentication === '0' || userApproveInfo.isAuthentication === '2'" @click="buyerApprove">买家未认证</div>
    <div class="sign4" v-else-if="userApproveInfo.isAuthentication === '1' || userApproveInfo.isAuthentication === '3'">买家已认证</div> -->
    <div class="avatar"
         flex="dir:top main:center cross:center">
      <img :src="info.avatar?setting.server.baseUrl + info.avatar:'/images/user.png'">
      <span>{{ userApproveInfo.contactPerson  }}</span>
    </div>
    <div class="row">{{ userApproveInfo.companyName }}</div>
    <div class="uc-btn">
      <el-button size="small" type="primary" v-if="userApproveInfo.isAuthentication === '2' || userApproveInfo.isAuthentication === '3'" round>我是卖家</el-button>
      <el-button size="small" type="info" v-else-if="userApproveInfo.isAuthentication === '0' || userApproveInfo.isAuthentication === '1'" round @click="sellerApprove">卖家认证</el-button>
      <el-button size="small" type="primary" v-if="userApproveInfo.isAuthentication === '1' || userApproveInfo.isAuthentication === '3'" round>我是买家</el-button>
      <el-button size="small" type="info" v-else-if="userApproveInfo.isAuthentication === '0' || userApproveInfo.isAuthentication === '2'" round @click="buyerApprove">买家认证</el-button>
    </div>
  </div>
</template>

<script>
import setting from '@/setting'
import { mapState } from 'vuex'
import { _GetUserRoles, _GetUserApprove } from '@/api/user'
import { _GetIndexUnreadMessage } from '@api/userIndex'

export default {
  name: 'UserInfoItem',
  data () {
    return {
      setting,
      name: '已认证',
      roles: null,
      userApproveInfo: {}, // 用户认证信息
      unreadNum: 0,
      timer: null
    }
  },
  computed: {
    // 获取当前登陆用户信息
    ...mapState('user', [
      'info'
    ]),
    idCard () {
      return !!this.info.certificateNumber
    },
    iphoneShow () {
      return !!this.info.phone
    },
    userRole () {
      let roleStr = ''
      this.roles && this.roles.forEach(role => {
        roleStr += role.roleName + '  '
      })
      return roleStr
    }
  },
  created () {
    const that = this
    this.getUserRoles()
    this.getUserApprove()
    this.getUnreadMessage()
    this.timer = setInterval(() => {
      that.getUnreadMessage()
    }, 5000)
  },
  destroyed () {
    clearInterval(this.timer)
  },
  methods: {
    // 获取用户权限信息
    getUserRoles () {
      _GetUserRoles().then(res => {
        this.roles = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取用户认证状态
    getUserApprove () {
      _GetUserApprove().then(res => {
        this.userApproveInfo = res
      }).catch(err => {
        console.log(err)
      })
    },
    // 卖方认证
    sellerApprove () {
      this.$router.push({
        path: '/uc/userApprove/:type',
        name: 'userApprove',
        params: { type: 1 }
      })
    },
    // 买方认证
    buyerApprove () {
      this.$router.push({
        path: '/uc/userApprove/:type',
        name: 'userApprove',
        params: { type: 0 }
      })
    },
    // 获取未读信息数量
    getUnreadMessage () {
      _GetIndexUnreadMessage().then(res => {
        this.unreadNum = res
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.u-i-container {
  background-color: #fff;
  position: relative;

  .r-icon {
    position: absolute;
    right: 0;
    top: 25px;
    width: 76px;
    height: 32px;
    border-radius: 16px 0px 0px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 32px;
    text-align: center;
  }

  .avatar {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    margin: 25px auto;

    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      margin: 5px;
    }
  }

  .row {
    text-align: center;
    color: rgb(138, 138, 138);
  }
  .uc-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
  }

  .bottom {
    margin: 30px 0 20px;

    span {
      margin-top: 8px;
      height: 13px;
      font-size: 13px;
      font-weight: 400;
      color: #717272;
      line-height: 20px;

      &.active {
        color: $color-primary;
      }
    }
  }
}
</style>
