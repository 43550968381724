<!--
 * @Description: 图片上传自定义组件
 * @Author: Pancras
 * @Date: 2019-10-24 14:17:51
 * @LastEditors: wut 1364342822@qq.com
 * @LastEditTime: 2023-03-29 19:48:51
 -->
<template>
  <div>
    <!-- 遮罩层 -->
    <div class="container"
         v-show="cropVisible">
      <!-- card 面板 -->
      <el-card shadow="always"
               class="card">
        <div slot="header"
             class="clearfix">
          <span>图片裁剪</span>
          <i class="el-icon-close"
             style="float: right;padding: 3px 0"
             @click="crop()"></i>
        </div>
        <!-- 第一行 开始 -->
        <el-row :gutter="10">
          <!-- 第一行第一列 开始 -->
          <el-col :span="16">
            <el-row>
              <el-col :span="24">
                <!-- 图片裁剪区域 -->
                <div class="imgPanel">
                  <img :src="currentImage.url"
                       class="img"
                       ref="image" />
                </div>
              </el-col>
            </el-row>
            <el-row class="d2-mt-10">
              <!-- 图片操作列 -->
              <el-col :span="24">
                <!-- 移动 裁剪 -->
                <el-button-group>
                  <el-tooltip content="移动图片"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-rank"
                               @click="cropper.setDragMode('move')"></el-button>
                  </el-tooltip>
                  <el-tooltip content="自定义裁剪"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-crop"
                               @click="cropper.setDragMode('crop')"></el-button>
                  </el-tooltip>
                </el-button-group>
                <!-- 放大 缩小 -->
                <el-button-group class="d2-ml-10">
                  <el-tooltip content="放大"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-zoom-in"
                               @click="cropper.zoom(0.1)"></el-button>
                  </el-tooltip>
                  <el-tooltip content="缩小"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-zoom-out"
                               @click="cropper.zoom(-0.1)"></el-button>
                  </el-tooltip>
                </el-button-group>
                <!-- 左右旋转 -->
                <el-button-group class="d2-ml-10">
                  <el-tooltip content="向左旋转"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-refresh-left"
                               @click="cropper.rotate(-45)"></el-button>
                  </el-tooltip>
                  <el-tooltip content="向右旋转"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-refresh-right"
                               @click="cropper.rotate(45)"></el-button>
                  </el-tooltip>
                </el-button-group>
                <!-- 上下翻转 -->
                <el-button-group class="d2-ml-10">
                  <el-tooltip content="上下翻转"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-sort"
                               @click="cropper.scaleX(-1)"></el-button>
                  </el-tooltip>
                  <el-tooltip content="左右翻转"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-sort"
                               @click="cropper.scaleY(-1)"></el-button>
                  </el-tooltip>
                </el-button-group>
                <!-- 还原 -->
                <el-button-group class="d2-ml-10">
                  <el-tooltip content="还原"
                              placement="top">
                    <el-button type="primary"
                               size="small"
                               icon="el-icon-refresh"
                               @click="cropper.reset()"></el-button>
                  </el-tooltip>
                </el-button-group>
              </el-col>
            </el-row>
          </el-col>
          <!-- 第一行第一列 结束 -->
          <!-- 第一行第二列 开始 -->
          <el-col :span="8">
            <el-row>
              <el-col :span="24">
                <div class="previewLarge"></div>
              </el-col>
            </el-row>
            <el-row class="d2-mt-10">
              <el-col :span="14">
                <div class="previewMedium"></div>
              </el-col>
              <el-col :span="10">
                <div class="previewSmall"></div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24"
                      class="d2-mt-20">
                <el-button type="success"
                           @click="crop()">确 定</el-button>
              </el-col>
            </el-row>
          </el-col>
          <!-- 第一行第二列 结束 -->
        </el-row>
        <!-- 第一行 结束 -->
      </el-card>
    </div>
    <!-- 图片预览,默认隐藏,通过 js 事件模拟点击显示 -->
    <div class="demo-image__preview"
         style="height:0px;overflow:hidden;">
      <el-image ref="previewImage"
                :src="previewImage"
                :preview-src-list="previewImageList">
      </el-image>
    </div>
    <el-upload ref="upload"
               v-loading="loading"
               :disabled="disabled"
               :action="action"
               :headers="headers"
               accept="image/jpeg,image/png"
               :on-preview="handlePreview"
               :on-change="handleChange"
               :on-success="handleSuccess"
               :on-error="handleError"
               :on-remove="handleRemove"
               :auto-upload="false"
               :limit="limit"
               :file-list="fileList"
               :on-exceed="handleExceed"
               list-type="picture-card"
               class="upload">
      <i slot="default"
         class="el-icon-plus"></i>
    </el-upload>
    <div v-if="limit!==1"
         style="font-size: 12px;color: #606266;">总计可上传
      <el-tag type="success"
              size="mini"
              hit>{{this.limit}}</el-tag> 张图片,已上传
      <el-tag type="warning"
              size="mini"
              hit>{{this.imageList.length}}</el-tag> 张!
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import util from '@/libs/util'
import setting from '@/setting'
import { setTimeout } from 'timers'
// import cookies from '@/libs/util.cookies'
export default {
  name: 'image-upload',
  inject: {
    elForm: {
      default: ''
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    // 裁剪宽度
    width: {
      type: Number,
      default: 800
    },
    // 裁剪高度
    height: {
      type: Number,
      default: 450
    },
    // 裁剪质量
    quality: {
      type: Number,
      default: 0.85
    },
    // 图片限制数量
    limit: {
      type: Number,
      default: 1
    },
    // 图片水印
    watermark: {
      type: Array,
      default: () => []
    },
    // 上传控件禁用状态
    disabled: {
      type: Boolean,
      default: false
    },
    // 已传文件列表,逗号分隔字符串
    imageList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false, // 加载状态
      cropper: '',
      croppable: false, // 是否初始化裁剪框
      currentImage: {}, // 当前编辑图片
      cropVisible: false, // 裁剪 dialog 显示状态
      fileList: [], // 上传文件列表
      previewImage: '', // 当前预览图
      previewImageList: [], // 预览图列表
      headers: {
        Authorization: 'Bearer ' + util.getToken().accessToken,
        'TENANT-ID': setting.server.tenantId
      },
      action: '/admin/sys-file/upload'// 图片上传 Action
    }
  },

  watch: {
    imageList: {
      handler (newValue, oldValue) {
        // 如果长度为空,则清空fileList
        if (newValue.length === 0) {
          this.fileList = []
        }
        if (!util.arrayEquals(newValue, oldValue)) {
          this.fileList = []
          this.loadFileList()
        }
      },
      immediate: true
    }
  },

  mounted () {
    // 初始化图片裁剪控件
    this.initCropper()
  },

  updated () {
    this.$nextTick(() => {
      this.renderUpload()
    })
  },

  methods: {
    /**
      * handleChange
      * @description 文件状态改变处理
      * @param {Object} file  文件对象
      * @param {Object} fileList 文件列表
    */
    handleChange (file, fileList) {
      // 添加文件时裁剪图片
      if (file.status === 'ready') {
        // 若是图片,执行裁剪
        if (this.isImage(file)) {
          this.currentImage = file
          // 每次替换图片要重新得到新的url
          if (this.cropper) {
            this.cropper.replace(file.url)
          }
          this.cropVisible = true // 显示裁剪框
        } else { // 不是图片
          // 用户提示
          this.$confirm('请上传图片文件! 支持格式:JPG,JEPG,BMP,PNG,GIF.', '提示', {
            confirmButtonText: '确定',
            type: 'warning'
          })
          // 取消上传请求
          this.$refs.upload.abort(file)
        }
      }
    },

    /**
      * handleSuccess
      * @description 文件上传成功处理
      * @param {Object} response 接口相应请求
      * @param {Object} file  文件对象
      * @param {Object} fileList 文件列表
    */
    handleSuccess (response, file, fileList) {
      // 将图片名称替换为后台返回的名称
      file.url = response.data.url
      // 更新父组件 model
      this.$emit('change', fileList.map(i => i.url).join())
      // 重新设置预览列表
      this.previewImageList = fileList.map(i => i.url)
    },

    /**
      * handleError
      * @description 文件上传失败处理
    */
    handleError () {
      this.$message({
        type: 'info',
        message: '图片上传失败,请稍后再试!'
      })
    },

    /**
     * handleExceed
     * @description 图片数量超出限制
     * @param {Object} file 文件对象
     * @param {Object} fileList 文件列表
    */
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制上传 ${this.limit} 个文件，您已上传了 ${fileList.length} 个文件`)
    },

    /**
      * handleRemove
      * @description TODO:移除图时处理,后台暂未提供接口
      * @param {Object} file  文件对象
      * @param {Object} fileList 文件列表
    */
    handleRemove (file, fileList) {
      // 更新父组件 model
      this.$emit('change', fileList.map(i => i.url).join())
      // 重新设置预览列表
      this.previewImageList = fileList.map(i => i.url)
    },

    /**
      * handlePreview
      * @description 预览图片
      * @param {Object} file  文件对象
    */
    handlePreview (file) {
      // 当前预览图片 url,必须赋值,否则无法点击
      this.previewImage = file.url
      // 删除当前 url
      this.previewImageList.splice(this.previewImageList.indexOf(file.url), 1)
      // 将当前 url 放在第一个
      this.previewImageList.unshift(file.url)
      // 100毫秒后模拟点击
      setTimeout(() => {
        this.$refs.previewImage.$el.childNodes[0].click()
      }, 100)
    },

    /**
      * loadFileList
      * @description 加载图片列表
    */
    loadFileList () {
      if (this.imageList.length !== 0) {
        this.loading = true
        // 初始化文件列表
        this.imageList.forEach(imageUrl => {
          // 处理返回的文件流
          let obj = {
            url: imageUrl
          }
          this.fileList.push(obj)
          this.previewImageList.push(imageUrl)
        })
        this.loading = false
      }
    },

    /**
     * renderUpload
     * @description 渲染上传控件
    */
    renderUpload (fileList) {
      let uploader = this.$refs.upload
      if (uploader && uploader.$el) {
        let control = uploader.$el.lastChild
        // 文件数量达到上限,则隐藏上传控件
        if (uploader.uploadFiles.length >= this.limit) {
          // 隐藏上传控件
          control.style.display = 'none'
        } else {
          // 隐藏上传控件
          control.style.display = 'inline-block'
        }
      }
    },

    /**
      * initCropper
      * @description 初始化 cropper
    */
    initCropper () {
      let _this = this
      this.cropper = new Cropper(this.$refs.image, {
        viewMode: 2,
        aspectRatio: this.width / this.height, // 裁剪框比例
        preview: '.previewLarge,.previewMedium,.previewSmall', // 预览图片
        ready: function () {
          _this.croppable = true
        }
      })
    },

    /**
     * crop
     * @description 图片裁剪
    */
    crop () {
      let _this = this
      this.cropVisible = false
      // 获取裁剪画布,透明区域填充白色(重要))
      let croppedCanvas = this.cropper.getCroppedCanvas({ width: this.width, height: this.height, fillColor: '#fff' })
      this.setWatermark(croppedCanvas)
      // 获取裁剪后对象并上传
      croppedCanvas.toBlob(async function (blob) {
        let file = new window.File([blob], _this.currentImage.name, { type: 'image/jpeg' })
        file.uid = _this.currentImage.uid
        _this.currentImage.raw = file// 原始文件
        _this.currentImage.size = file.size// 文件大小
        // 重设当前图片 url (替换原始图片)
        let url = window.URL.createObjectURL(blob)
        _this.currentImage.url = url
        // 执行上传
        _this.$refs.upload.submit()
      }, 'image/jpeg', _this.quality)
    },

    /**
     * isImage
     * @description  根据扩展名及 mimeType 判断是否是图片文件
     * @param {Object} file  文件对象
    */
    isImage (file) {
      var fileName = file.name
      let fileType = file.raw.type
      // 根据文件 mimeType  判断是否是图片
      if (fileType.indexOf('image/') === -1) {
        return false
      } else {
        // 根据文件扩展名判断是否是图片
        var suffixIndex = fileName.lastIndexOf('.')
        var suffix = fileName.substring(suffixIndex + 1).toUpperCase()// 文件扩展名
        if (suffix !== 'BMP' && suffix !== 'JPG' && suffix !== 'JPEG' && suffix !== 'PNG' && suffix !== 'GIF') {
          return false
        } else {
          return true
        }
      }
    },

    /**
     * SetWatermark
     * @description 设置图片水印
     * @param {Object} sourceCanvas 原始画布
    */
    setWatermark (sourceCanvas) {
      // 获取水印数组
      let watermark = this.watermark
      // 水印缩放比例
      let scale = this.height / 768
      // 定义水印画布
      let canvas = document.createElement('canvas')
      // 设置画布的尺寸
      canvas.width = sourceCanvas.width
      canvas.height = sourceCanvas.height
      // 获取画布绘图环境
      let ctx = canvas.getContext('2d')
      // 根据缩放比例定义文字大小,最小值 18px
      let fontSize = 24 * scale > 14 ? 24 * scale : 14
      /**
       * fillStyle：设置或返回用于填充绘画的颜色、渐变或模式
       * font：设置或返回画布上文本内容的当前字体属性，如下所示 字号 字体
       * textBaseline:文字基线位置
      */
      ctx.fillStyle = '#fff'
      ctx.font = 'bold ' + fontSize + 'px Verdana PingFangSC-Regular microsoft yahei'
      ctx.textBaseline = 'top'
      // 绘制水印
      for (let i = 0; i < watermark.length; i++) {
        let num = i + 1
        let x = 10
        let y = (num - 1) * fontSize + num * fontSize * 0.3
        ctx.fillText(watermark[i], x, y)
      }
      let sContext = sourceCanvas.getContext('2d')
      // 叠加水印
      sContext.drawImage(canvas, 15, 15)
    }
  }
}
</script>
<style lang="scss" scoped>
/* upload 过渡动画 */
/* 设置持续时间和动画函数 */
.slide-fade-enter-active {
  transition: all 0.4s ease;
}
.slide-fade-leave-active {
  transition: all 0.6s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

/* 裁剪框 样式 */
/* 公共变量定义,自动处理宽高比 */
$cardWidth: 700px;
$rowWidth: 660px; //card 左右边距:40
$colWidth: $rowWidth/24;
// 遮罩层
.container {
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}
// 卡片面板 59+40+10+32+10
.card {
  width: $cardWidth;
  height: $colWidth * 16/1.5+151;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
// 图片容器
.imgPanel {
  $imgRowWidth: $colWidth * 16;
  width: $colWidth * 16 - 10;
  height: ($colWidth * 16 - 10)/1.5;
}
// 限制图片宽度
.img {
  max-width: 100%; // 该样式非常重要,不要移除该样式!
}

// 预览图行宽
$previewWidth: $colWidth * 8 - 10;
// 预览图 大
.previewLarge {
  width: $previewWidth;
  height: $previewWidth/1.5;
  overflow: hidden;
}
// 预览图 中
.previewMedium {
  width: ($previewWidth - 10)/24 * 14;
  height: ($previewWidth - 10)/24 * 14/1.5;
  overflow: hidden;
}
// 预览图 小
.previewSmall {
  width: ($previewWidth - 10)/24 * 10;
  height: ($previewWidth - 10)/24 * 10/1.5;
  vertical-align: bottom;
  overflow: hidden;
}
</style>
